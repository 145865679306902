<template>
  <div>
    <AppBar />
    <!-- overflow-y-auto h100vh cust-scroll -->
    <div>
      <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition"
          v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
          <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
          {{ snackmsgbar }}
          <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10"
          transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true"
          :color="snackbarclr" absolute text-color="white">
          <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
          {{ snackmsgbar }}
          <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
          <router-view />
        </div>

        <!-- <div v-if="bannercondition != '/pms' || bannercondition != '/ipo' || bannercondition != '/mutualfunds' || bannercondition != '/insurance' || bannercondition != '/aif' || bannercondition != '/partner'"> -->
        <v-card v-if="appdialog" width="100%" class="pos-rlt d-none d-md-flex elevation-0 mx-auto ml-sm-4">
          <div style="position: fixed !important; bottom: 0; z-index: 6; right: 0px">
            <v-icon style="position: absolute; top: 10px; right: 10px" size="24" @click="appdialog = false"
              color="#000">mdi-close-circle</v-icon>
            <a href="https://zebuetrade.com/apps" target="_blank">
              <img src="https://zebuetrade.com/asset/static/img/apppromo.png" class="app-promo rounded-xl" width="300px"
                alt="apppromo" />
            </a>
          </div>
          <!--   <v-expand-transition>
            <v-card v-show="appdialog" class="app-download  apps-box elevation-0">
            <v-icon @click="appdialog = false" class="pos-abs app-download-icon" color="#000">mdi-close-circle</v-icon>
            <v-card class="pt-4 pb-2 text-center rounded-lg" color="#fff" width="140px">
              <p class="fs-13 font-weight-bold lh-16 mb-1">Download MYNT + <br> Mobile app</p>
              <div class="py-1">
                <img width="70%" src="@/assets/apps/bannerS.png" alt="qrcode" class="">

                <img width="70%" src="@/assets/qrcode.svg" alt="qrcode" class="">
              </div>
              <div class="d-inline-flex">
                <a href="https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu" target="_blank"
                  rel="noopener noreferrer">
                  <img src="@/assets/playstore.svg" alt="playstore" class="mr-1">
                </a>
                <a href="https://apps.apple.com/in/app/mynt/id6478270319" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/apple.svg" alt="apple" class="ml-1">
                </a>
              </div>
            </v-card>
          </v-card> 
          </v-expand-transition>-->
        </v-card>
        <!-- </div> -->
        <v-expand-transition>
          <v-card v-show="banndialog" class="elevation-0">
            <div class="webbanner d-none d-sm-block">
              <v-icon @click="setBannerPop()" class="pos-abs webbanner-icon">mdi-close</v-icon>
              <v-carousel cycle hide-delimiters :show-arrows="false">
                <v-carousel-item v-for="(j, i) in bannerdata" :key="i">
                  <a :href="j.url" target="_blank"> <img :src="j.img" :alt="j.tit" class="rounded-lg"
                      width="100%" /></a>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-card>
        </v-expand-transition>

        <!-- <v-expand-transition class="">
          <v-card v-show="banndialogsignup" class="elevation-0">
            <div class=" ">

              <v-card v-if="showbox" elevation="2" color="#E5EBEC" width="100%" class="webbannerinshow rounded-xl ">
                <v-toolbar elevation="0" color="#E5EBEC" dense class=" mt-1 pa-0">
                  <p class="font-weight-bold mb-4 fs-18 mt-2 black--text">Sign Up Now! </p>

                  <v-spacer></v-spacer>

                  <v-btn class="mr-0" icon @click="closecard()">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="px-4 pb-4">
                  <form @submit.prevent="formadataipo">

                    <v-text-field v-model="formaname" :disabled="loader" :rules="nameRules" required block flat solo
                      background-color="#ffffff" class="elevation-0  rounded-pill caption" label="Enter your name">
                      <template #prepend-inner>
                        <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2" alt="user-icon" />
                      </template>
</v-text-field>

<v-text-field v-model="frommobile" :maxlength="10" :disabled="loader" :rules="mobileRules" required block flat solo
  background-color="#ffffff" class="elevation-0 rounded-pill caption" label="Enter your mobile number">
  <template #prepend-inner>
                        <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2" alt="phone-icon" />
                      </template>
</v-text-field>
<v-text-field v-model="formemail" :disabled="loader" :rules="emailRules" required block flat solo
  background-color="#ffffff" class="elevation-0 rounded-pill caption" label="Enter your email address">
  <template #prepend-inner>
                        <img src="@/assets/contactus/send-message.svg" width="100%" class="mx-2" alt="send-msg-icon" />
                      </template>
</v-text-field>
<v-row>

  <v-col cols="12">

    <v-btn type="submit" block large rounded :color="valid ? '#FFF07E' : '#FFF07Ec2'" :loading="loader" height="48px"
      class="elevation-0 mr-7 text-none">
      <span class="black--text px-7">Submit</span>
    </v-btn>
  </v-col>

</v-row>
</form>
</div>
</v-card>

<div class="webbannerins" v-if="signupbtn">

  <v-btn @click="opencard()" class=" mx-2 white--text text-none elevation-0  " rounded large
    :color="valid ? '#0037B7' : '#0037B7'">
    <svg width="25" height="20" viewBox="0 0 460 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M71.25 0H336.75L337.75 0.5L346.25 2.25L352.25 4L357.5 6L363.75 9L367.75 11.25L372 14L375 16.25L378.5 19L382.25 22.5L386 26.25L390.5 31.75L393.5 36L396.5 41L399 45.75L401.5 51.75L403.5 57.75L405 63.75L406 70.25L406.5 76.25V205L405.75 208.75L404.5 211.75L402.75 214.75L400.5 217.5H400V218L397.25 220L393.75 221.75L390.25 222.75L388.5 223H384L380.5 222.25L377.5 221L374.5 219.25L371.5 216.5L369.25 213.5L367.5 210L366.5 206.25L366.25 203.75L366 79L365.25 72L363.25 65.25L361.25 61L358.5 56.5L355.25 52.75L354.25 51.5L350.75 48.75L348.25 46.75L342.75 43.75L336 41.5L333.75 41L328.75 40.5L323.25 40.25H82.75L75 40.75L70 41.75L64.75 43.5L58.75 46.75L54.75 50L52.25 52.25L50.25 54.5L47.25 58.5L44.25 64L42.25 69.75L41.25 75.25L41 78V433.75L41.5 438.5L42.5 443L44.25 448L46.75 452.75L49 456L52.25 459.75L55.25 462.5L59 465.25L64.5 468.25L68 469.5L71.25 470.5L76.75 471.25L79.5 471.5L147 471.75L152 472.25L155.75 473.5L159.5 475.75L162.5 478.5L164.5 481.25L166.5 485.25L167.5 489.5V494.25L166.75 497.75L165.5 501L163.25 504.5L161 507L157.75 509.25L154.25 511L151.75 511.75L151.5 512H70.5L70 511.5L62.25 510L55.75 508.25L48.5 505.5L41.5 502L36.25 498.75L31.75 495.5L29 493.25L25.75 490.25L23.75 488.5L20.25 484.75L16.75 480.5L13.75 476.25L11.75 473L9.75 469.5L7 464L4.5 457.5L2.5 450.5L1.5 445.5L0.75 440L0.25 431V80L0.75 71.5L2.25 62.5L4.25 55.25L6.75 48.5L10 41.75L13.5 36L16 32.5L18.5 29.25L20.75 26.75L22.5 24.75L27.25 20L29.25 18.25L32.25 16L36 13.25L43 9L49.25 6L56 3.5L63.75 1.5L70.75 0.25L71.25 0Z"
        fill="white" />
      <path
        d="M398 271.75H401.25L407.5 272.25L413 273.25L418.25 274.75L423.5 276.75L427.75 278.75L432.75 281.75L437 285L439.75 287.25L445 292.5L448.25 296.75L450.75 300.5L453 304.5L455 308.75L457.25 315L458.75 321.5L459.5 327.75V336.5L458.5 343.75L457 349.75L455 355.25L452.5 360.5L450 364.75L447.5 368.25L445.25 371L442.5 374L440.75 376L439.75 377H439.25L438.75 378L437 379.5L334.5 482H334L333.5 483H333V483.5L330.5 485.5L327.75 487.25L324.25 488.75L316.75 491L257 507.5L243.75 511.25L240.75 512H233.5L228.5 510L226 508.5L223.5 506.5V506H223L220.5 502.75L218.5 498.75L217.75 496.5L217.25 492.75V490.75L218.25 485.5L221.5 474.75L225 463.25L228.25 452.5L231.75 441L236 427L240 413.75L242 407.25L244 402.75L246.25 400L249 397L298.5 347.5H299L299.5 346.5H300L300.5 345.5H301L301.5 344.5H302L302.5 343.5H303L303.25 342.75L304.75 341.5L306.25 339.75L308 338.25L309.25 336.75L311 335.25L312.25 333.75L314.25 332L358.5 287.75L360.5 286L365 282.5L370.25 279.25L374.25 277.25L377.75 275.75L383 274L388.5 272.75L393.75 272L398 271.75ZM396.5 312.25L392.5 313.25L389 315L385.75 317.5L382 321.25L382.25 322.25L384 324.25L407.25 347.5L408.75 348.75L409.5 349.5L410.5 349L414.75 344.75L417 341.25L418.25 338.5L419 335.75L419.25 331L418.75 327.25L417.5 323.75L415.75 320.5L413 317.25L409.5 314.75L406.25 313.25L402 312.25H396.5ZM353 350.25L351 352V352.5H350.5V353H350V353.5H349.5V354H349V354.5H348.5V355H348V355.5H347.5V356H347V356.5H346.5V357H346V357.5H345.5V358H345V358.5L344 359V359.5L343 360V360.5H342.5V361H342V361.5H341.5V362H341V362.5L340 363V363.5L339 364V364.5L338 365L279.25 423.75L277.5 429L274.25 439.75L268.5 458.75L267.5 461.75V462.5L269.5 462.25L305.75 452.25L308 451.25L310.5 449L381 378.5L380.5 377.25L354.25 351H353.75L353.5 350.25H353Z"
        fill="white" />
      <path
        d="M100.75 199.75H305.75L311 200.25L314.75 201.5L318.5 203.75L321.5 206.5L324 210L325.75 214L326.5 217.5V222.25L325.75 225.75L324.5 229L322.75 231.75L320.5 234.5L317.25 237L313.75 238.75L310 239.75L307.75 240H98.75L95.5 239.5L92.25 238.5L89.25 236.75L86 234L83.5 231L81.5 227L80.5 223.25L80.25 221.25V218.75L81 214.5L82.25 211.25L84 208.25L87 205L89 203.25L92.75 201.25L96 200.25L100.75 199.75Z"
        fill="white" />
      <path
        d="M100.75 119.75H305.75L311 120.25L314.75 121.5L318.5 123.75L321.5 126.5L324 130L325.75 134L326.5 137.5V142.25L325.75 145.75L324.5 148.75L323 151.5L320.25 154.75L317.25 157L313.75 158.75L310 159.75L307.75 160H98.75L95.5 159.5L92.25 158.5L89.25 156.75L86 154L83.5 151L81.25 146.25L80.5 143.25L80.25 141.25V138.75L81 134.5L82.25 131.25L84 128.25L87 125L89 123.25L92.75 121.25L96 120.25L100.75 119.75Z"
        fill="white" />
      <path
        d="M100.75 279.75H224.5L230 280.25L233.25 281.25L237.25 283.5L240.25 286.25L242 288.25L244 291.75L245.25 295.5L245.75 298.5V301.25L245 305.5L243.75 308.75L241.75 312L239 315L236 317.25L232.75 318.75L229 319.75L227 320H98.75L95.5 319.5L92.25 318.5L88.5 316.25L85.5 313.5L83.25 310.5L81.25 306.25L80.5 303.25L80.25 301.25V298.75L81 294.5L82.25 291.25L84 288.25L87 285L89 283.25L92.75 281.25L96 280.25L100.75 279.75Z"
        fill="white" />
    </svg>
    Sign Up Now!
  </v-btn>
</div>


</div>
</v-card>
</v-expand-transition> -->
      </v-container>
      <div class="sec-footer">
        <FooterSet />
      </div>
    </div>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card class="pb-5 overflow-hidden" color="cardbg">
        <v-card class="elevation-0   pa-4" height="50" color="secbg">



          <div class="d-flex">
            <p class="font-weight-bold fs-16 maintext--text mb-0">
              Direct MF
            </p>
            <!-- <div class="d-inline-flex   ml-auto">
             
              <p class="font-weight-regular fs-14 subtext--text mb-2">Change Type : </p>

              <v-card @click="buyvalue = !buyvalue" class="mt-n1 ml-2" width="40px" height="25px"
                :color="buyvalue == false ? 'red' : 'green'">
                <p v-if="buyvalue" class="fs-14 font-weight-bold white--text pa-1">Buy</p>
                <p v-else class="fs-14 font-weight-bold white--text pa-1">Sell</p>
              </v-card>
            </div> -->
          </div>

        </v-card>
        <div class="px-4 px-md-6 pt-4 pb-2">

          <!-- <div>
            <p class="font-weight-regular fs-14 subtext--text mb-2">Choose tenure</p>
            <div class="d-inline-flex mb-4">
              <span class="fs-14 font-weight-bold mt-2"
                :class="!mf_tenure ? 'maintext--text' : 'subtext--text'">Lumpsum</span>
              <v-switch v-model="mf_tenure" hide-details class="mx-5 cust" inset></v-switch>
              <span class="fs-14 font-weight-bold mt-2" :class="mf_tenure ? 'maintext--text' : 'subtext--text'">Monthly
                SIP</span>
            </div>
          </div> -->
          <div v-if="mf_tenure == false">
            <p class="font-weight-regular fs-14 subtext--text mt-3 mb-2">Client ID</p>
            <v-text-field :placeholder="Errorplaceholder == 'Netword Error' ? Errorplaceholder : ''" :disabled="disabledmf"
              oninput="this.value = this.value.toUpperCase()" height="40px" background-color="secbg" flat
              class="rounded-pill" solo hide-details hide-spin-buttons v-model="mf_client_id">
              <!-- @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt >= menudata.item.Minimum_Purchase_Amount ? mf_invest_amt : menudata.item.Minimum_Purchase_Amount"
                          :min="menudata.item.Minimum_Purchase_Amount"
                          :step="menudata.item.Minimum_Purchase_Amount" -->
              <!-- <template #append>
                            <v-card @click="mf_invest_amt += menudata.item.Minimum_Purchase_Amount" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.item.Minimum_Purchase_Amount }}
                            </v-card>
                          </template> -->

              <template #prepend-inner>
                <img src="../assets/support//kb1.svg" width="55%" alt="" class="ml-1">
              </template>
            </v-text-field>
            <p class="font-weight-regular mt-3 fs-14 subtext--text mb-2">Select Fund</p>
            <v-autocomplete clearable oninput="this.value = this.value.toUpperCase()" :placeholder="Errorplaceholder" :disabled="disabledmf" :loading="mflistloader"
              @update:search-input="mfsearch" @change="autochange" height="40px" background-color="secbg" flat
              class="rounded-pill" solo hide-details hide-spin-buttons v-model="mf_invest_fund_name" :items="mf_data"
              item-text="Scheme_Name">
              <!-- @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt >= menudata.item.Minimum_Purchase_Amount ? mf_invest_amt : menudata.item.Minimum_Purchase_Amount"
                          :min="menudata.item.Minimum_Purchase_Amount"
                          :step="menudata.item.Minimum_Purchase_Amount" -->
              <!-- <template #append>
                            <v-card @click="mf_invest_amt += menudata.item.Minimum_Purchase_Amount" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.item.Minimum_Purchase_Amount }}
                            </v-card>
                          </template> -->

              <template v-slot:item="data">

                <template>

                  <v-list-item-content>
                    <div style="width: 200px; white-space: normal; word-wrap: break-word;">
                      <v-list-item-title v-html="data.item.Scheme_Name"></v-list-item-title>
                    </div>
                  </v-list-item-content>

                </template>
              </template>
              <template #prepend-inner>
                <img src="../assets/support//kb1.svg" width="55%" alt="" class="ml-1">
              </template>
            </v-autocomplete>
            <p class="font-weight-regular fs-14 subtext--text mt-3 mb-2">Investment amount</p>
            <v-text-field :placeholder="Errorplaceholder == 'Netword Error' ? Errorplaceholder : ''" :disabled="disabledmf" height="40px" background-color="secbg"
              flat class="rounded-pill" solo type="number" hide-details hide-spin-buttons v-model="mf_invest_amt"
              @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt >= SelectedItem.Minimum_Purchase_Amount ? mf_invest_amt : SelectedItem.Minimum_Purchase_Amount"
              :min="SelectedItem.Minimum_Purchase_Amount" :step="SelectedItem.Minimum_Purchase_Amount">

              <template #append>
                <v-card style="background-color: #F1F3F8;"
                  @click="mf_invest_amt = Number(mf_invest_amt) + Number(SelectedItem.Minimum_Purchase_Amount)"
                  class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">

                  {{ SelectedItem.Minimum_Purchase_Amount ? `+${SelectedItem.Minimum_Purchase_Amount}` : '' }}
                </v-card>
              </template>

              <template #prepend-inner>
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                    fill="white" />
                  <path
                    d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                    fill="#999999" />
                </svg>
              </template>
            </v-text-field>
            <p v-if="SelectedItem.Minimum_Purchase_Amount" class="fs-10 subtext--text ml-6 mt-1">Min. ₹{{
              SelectedItem.Minimum_Purchase_Amount }} (multiple of 1.0)
            </p>



          </div>
          <div v-else>
            <p class="font-weight-regular fs-14 subtext--text mt-3 mb-2">Client ID</p>
            <v-text-field oninput="this.value = this.value.toUpperCase()" height="40px" background-color="secbg" flat
              class="rounded-pill" solo hide-details hide-spin-buttons v-model="mf_client_id">
              <!-- @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt >= menudata.item.Minimum_Purchase_Amount ? mf_invest_amt : menudata.item.Minimum_Purchase_Amount"
                          :min="menudata.item.Minimum_Purchase_Amount"
                          :step="menudata.item.Minimum_Purchase_Amount" -->
              <template #append>
                <v-btn icon small
                  class="text-none rounded-pill elevation-0 btntext--text px-6 ml-4"><v-icon>mdi-arrow-right-circle</v-icon></v-btn>
              </template>

              <template #prepend-inner>
                <img src="../assets/support//kb1.svg" width="55%" alt="" class="ml-1">
              </template>
            </v-text-field>
            <p class="font-weight-regular fs-14 subtext--text mb-2">
              Mandates
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <rect width="20" height="15" rx="7" fill="#2DB266" />
                      <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <rect width="20" height="15" rx="7" fill="#DC2626" />
                      <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                </template>
                <!-- <span>{{ mf_mandate && mf_mandate.Status == "APPROVED" ? "Valid" : "Not valid" }}</span> -->
                <span> "Not valid" </span>
              </v-tooltip>
              <span class="float-right">Amt: <b>5000</b></span>
            </p>


            <!-- <div v-if="mf_mandates.length > 0">
              <v-select v-model="mf_mandate" :disabled="!mf_mandates" :items="mf_mandates"
                append-icon="mdi-chevron-down" item-text="MandateId" item-value="MandateId" return-object
                background-color="secbg" flat class="rounded-pill mb-0" hide-details solo>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs"> 
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{ item.MandateId }}

                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <svg v-if="item.Status == 'APPROVED'" xmlns="http://www.w3.org/2000/svg" width="20"
                                height="15" viewBox="0 0 20 15" fill="none">
                                <rect width="20" height="15" rx="7" fill="#2DB266" />
                                <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <svg
                                v-else-if="item.Status == 'REJECTED' || item.Status == 'INITIAL REJECTION' || item.Status.includes('REJECT')"
                                xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15"
                                fill="none">
                                <rect width="20" height="15" rx="7" fill="#DC2626" />
                                <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15"
                                fill="none">
                                <rect width="20" height="15" rx="7" fill="#FFB038" />
                                <path
                                  d="M5.3125 8.75C6.00286 8.75 6.5625 8.19036 6.5625 7.5C6.5625 6.80964 6.00286 6.25 5.3125 6.25C4.62214 6.25 4.0625 6.80964 4.0625 7.5C4.0625 8.19036 4.62214 8.75 5.3125 8.75Z"
                                  fill="white" />
                                <path
                                  d="M10.3125 8.75C11.0029 8.75 11.5625 8.19036 11.5625 7.5C11.5625 6.80964 11.0029 6.25 10.3125 6.25C9.62214 6.25 9.0625 6.80964 9.0625 7.5C9.0625 8.19036 9.62214 8.75 10.3125 8.75Z"
                                  fill="white" />
                                <path
                                  d="M15.3125 8.75C16.0029 8.75 16.5625 8.19036 16.5625 7.5C16.5625 6.80964 16.0029 6.25 15.3125 6.25C14.6221 6.25 14.0625 6.80964 14.0625 7.5C14.0625 8.19036 14.6221 8.75 15.3125 8.75Z"
                                  fill="white" />
                              </svg>
                            </span>
                          </template>
                          <span>{{ item.Status }}</span>
                        </v-tooltip>
                        <span class="float-right">{{ Number(item.Amount).toFixed(2) }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">reg date : {{ item.RegnDate }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <p class="text-right mb-2"><v-btn @click="(mf_tenure = null), setDefaultmandate()" color="primary" small
                  class="text-none font-weight-bold" text plain>+ Create mandate</v-btn></p>
            </div>
            <v-btn v-else @click="mf_tenure = null" :loading="orderpoploader" color="btnclr"
              class="text-none rounded-pill elevation-0 btntext--text mb-4" block height="48px">+ Create mandate
            </v-btn> -->


          </div>
          <v-toolbar class="tool-sty elevation-0 pt-4 mb-2   crd-trn" dense>
            <span class="font-weight-regular fs-10 subtext--text d-none d-md-block">
              AUM <span class="primary--text font-weight-bold"> </span> {{ (SelectedItem.AUM / 10000000).toFixed(2)
              }}Cr.
              <br />
              NAV <span class="primary--text font-weight-bold">₹{{ SelectedItem.NET_ASSET_VALUE }} </span>
            </span>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false" color="secbg" class="text-none rounded-pill elevation-0 subtext--text px-6"
              height="40px">
              Cancel
            </v-btn>
            <!-- :class="{
              'white--text': checkfun(),
              'white--text': !checkfun()
            }" -->

            <!-- || mf_invest_fund_name == [] -->
            <v-btn @click="sendorder" :disabled="checkfun()

              " :color="buyvalue == true ? 'green' : 'red'"
              class="text-none rounded-pill elevation-0 white--text px-6 ml-4" height="40px">
              {{ buyvalue == true ? 'Buy' : 'Sell' }}
            </v-btn>
            <!-- {{}} -->
          </v-toolbar>
        </div>
      </v-card></v-dialog>

  </div>
</template>

<script>
import AppBar from "../components/AppbarSet.vue";
import FooterSet from "../components/FooterSet.vue";
import eventBus from "../eventBus.js";
import axios from "axios";
import { strapi } from "../apiurl.js";

export default {
  data: () => ({
    appdialog: false,
    banndialog: false,
    // banndialogsignup: false,
    // showbox: false,
    signupbtn: false,
    mf_tenure: false,
    mf_invest_amt: '',
    bannerdata: [],
    formaname: "",
    mf_buyrsell: false,
    dialog: false,
    frommobile: "",
    formemail: "",
    mf_invest_fund_name: "",
    nameRules: [(v) => !!v || "Your Name is required", (v) => (v && v.length >= 3) || "Name must be more than 3 characters"],
    emailRules: [(v) => !!v || "Your E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    mobileRules: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
    loader: false,
    mflistloader: false,
    mf_client_id: '',
    disabledmf: false,
    valid: true,
    signupValue: "",
    pathurl: "",
    snackbar: false,
    snackbarclr: "#000",
    snackmsgbar: "",
    Errorplaceholder: 'Search here More then 2 Letter EX: REL',
    buyvalue: true,
    bannercondition: "",
    mf_data: [],
    SelectedItem: {},
  }),

  created() {
    let b = localStorage.getItem("banner");

    if (b != new Date().getHours()) {
      // this.getBannerData();
    }


    this.myOnLine();
  },
  mounted() {
    var styleElement = document.createElement("style");
    styleElement.appendChild(
      document.createTextNode(
        "::-webkit-scrollbar {width: 8px; height:6px} *, .v-application, .v-application .body-1, .v-application .body-2, .v-application .caption, .v-application .display-1, .v-application .headline, .v-application .overline, .v-application .subtitle-1, .v-application .subtitle-2, .v-application .title, body, html { font-family: Tenon !important; }"
      )
    );
    document.getElementsByTagName("head")[0].appendChild(styleElement);
    eventBus.$on("snack-event", (v) => {
      // this.bannercondition = color
      // console.log("this.bannercondition",this.bannercondition);
      this.displaybanner(v)
      this.mfdialogue()
    });
    // const pathSegment = this.$route.params;

    // // Log the path segment to the console
    // console.log('ddsjkds',pathSegment);

    // const params = new URLSearchParams(url.search);
    // this.queryParams = Object.fromEntries(params);
    // console.log('url',url.pathname,'params',params,'this.queryParams',this.queryParams);

    //  this.displaybanner()
    // setTimeout(() => {
    //   this.banndialogsignup = false;
    //   this.showbox = false;
    // }, 3000);
  },
  beforeDestroy() {
    eventBus.$off("signup", this.handleSignupEvent);

    eventBus.$off("snack-event");
  },
  methods: {
    checkfun() {
      return (
        (this.SelectedItem.Minimum_Purchase_Amount
          ? Number(this.mf_invest_amt) < this.SelectedItem.Minimum_Purchase_Amount
          : true) ||
        this.mf_invest_amt === '' ||
        this.mf_client_id.length < 4 ||
        this.mf_invest_fund_name === ''
      );
    },
    autochange(item) {
      console.log(item);
      this.SelectedItem = this.mf_data.find(element => element.Scheme_Name === item);
      this.mf_invest_amt = this.SelectedItem.Minimum_Purchase_Amount
      console.log(this.SelectedItem);


    },
    sendorder() {
      let data = JSON.stringify({
        "client_code": this.mf_client_id,
        "order_type": this.mf_tenure == false ? 'NRM' : 'SIP',
        "lumpsum_amount": this.mf_invest_amt,
        "buysell_type": this.buyvalue == true ? 'P' : 'R',
        "scheme_code": this.SelectedItem.Scheme_Code,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://v3.mynt.in/mf/add_orders',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosThis = this
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.stat == 'Ok') {
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "green";
            axiosThis.snackmsgbar = response.data.msg;
            axiosThis.dialog = false

          } else {
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "red";
            axiosThis.snackmsgbar = response.data.msg;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.snackbar = true;
          axiosThis.snackbarclr = "red";
          axiosThis.snackmsgbar = 'Network Error';
        });

    },
    mfdialogue() {

      const url = new URL(window.location.href);
      console.log(url);

      if (url.pathname.includes('directmf')) {
        this.dialog = true

        this.mflistloader = true
        this.disabledmf = true
        console.log("yes its coming");
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          // url: 'http://192.168.5.175:5002/master_file_datas_direct',
          url: 'https://v3.mynt.in/mf/master_file_datas_direct',
          headers: {}
        };
        let axiosThis = this
        axios.request(config)
          .then((response) => {
            if (response.data.stat == 'Ok') {
              axiosThis.mf_data = response.data.data
              axiosThis.mflistloader = false
              axiosThis.disabledmf = false


              console.log(axiosThis.mf_data);

            } else {
              axiosThis.mflistloader = false
              axiosThis.disabledmf = true
              axiosThis.Errorplaceholder = 'Funds not available'
            }
          })

          .catch((error) => {
            console.log(error);
            axiosThis.mflistloader = false
            axiosThis.Errorplaceholder = "Network Error"
            axiosThis.disabledmf = true
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "red";
            axiosThis.snackmsgbar = 'Network Error';


          });

      } else {
        console.log("not coming");
      }

    },

    mfsearch(val) {

      clearTimeout(this.searchTimeout);

      // Set a new timeout to wait until user stops typing
      this.searchTimeout = setTimeout(() => {
        // this.allsearchfunction(query) 

        this.fetchmfsearch(val); // Call the API or perform the search
      }, 500); // Wait for 500ms after the last keypress 


    },

    fetchmfsearch(val) {
      console.log();

      if (val && val.length > 2) {
        this.mflistloader = true
        let data = JSON.stringify({
          "text": val
        });
        let axiosThis = this

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://v3.mynt.in/mf/mfDirectsearch',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data.data && response.data != []) {
              axiosThis.mf_data = response.data.data
              axiosThis.mflistloader = false
              axiosThis.disabledmf = false


              console.log(axiosThis.mf_data);

            } else {
              axiosThis.mflistloader = false
              axiosThis.disabledmf = true
              axiosThis.Errorplaceholder = 'Funds not available'
            }

          })
          .catch((error) => {
            console.log(error);
          });
      }

    },
    displaybanner(v) {

      if (v == '/pms' || v == '/ipo' || v == '/mutualfunds' || v == '/insurance' || v == '/aif' || v == '/partner') {
        console.log("condii if", this.bannercondition);

        this.appdialog = false;

      } else {
        console.log("condii else", this.bannercondition);

        // setTimeout(() => {

        this.appdialog = true;
        // }, 3000);

      }

    },
    handleSignupEvent(value) {
      this.signupValue = value;
      console.log("Received value:", this.signupValue);
      console.log("Is value not 'landingpage'?", this.signupValue !== "landingpage");
    },
    opencard() {
      this.signupbtn = false;
      this.showbox = true;
    },
    closecard() {
      this.showbox = false;
      this.signupbtn = true;
    },
    getBannerData() {
      let config = {
        method: "get",
        url: `${strapi}/website-banner?populate=*`,
        headers: {},
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            var bann = res.data.data;
            for (let d = 0; d < bann.length; d++) {
              let b = bann[d].attributes;
              let i = strapi + bann[d].attributes.b_img.data.attributes.url;
              axiosThis.bannerdata.push({ img: i, url: b.url ? b.url : i, tit: b.title });
            }
            setTimeout(() => {
              axiosThis.banndialog = true;
            }, 8000);
          } else {
            axiosThis.banndialog = false;
            axiosThis.bannerdata = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setBannerPop() {
      localStorage.setItem("banner", new Date().getHours());
      this.banndialog = false;
    },
    myOnLine() {
      if (navigator.onLine) {
        // console.log("Connected to internet.");
      } else {
        // console.log("Unable to connect to internet.");
      }
    },
    formadataipo() {
      const url = new URL(window.location.href);
      this.pathurl = url.pathname;
      if (this.formaname != "" && this.frommobile != "" && this.formemail != "") {
        this.loader = true;
        const axios = require("axios");
        let data = JSON.stringify({
          client_name: this.formaname,
          mobile_number: this.frommobile,
          email: this.formemail,
          source: this.pathurl,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://dwld.zebuetrade.com/add`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        var axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.loader = false;
            axiosThis.dialog = false;
            if (response.data.stat == "Ok") {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "green";
              axiosThis.snackmsgbar = "Thank you for your response, One of our team representative will reach you shortly.";
            } else {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "red";
              axiosThis.snackmsgbar = response.data;
            }
            axiosThis.formaname = "";
            axiosThis.frommobile = "";
            axiosThis.formemail = "";
          })

          .catch((error) => {
            console.log(error);
            axiosThis.dialog = false;
            axiosThis.loader = false;
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "error";
            (axiosThis.snackmsgbar = error), (axiosThis.formaname = "");
            axiosThis.frommobile = "";
            axiosThis.formemail = "";
          });
      } else {
        this.snackbar = true;
        this.snackbarclr = "error";
        this.snackmsgbar = "Please fill the all fields";
      }
    },
  },
  components: {
    FooterSet,
    AppBar,
  },
};
</script>
<style>
@import "../assets/style/style.css";
</style>
