<template>
    <div class="no-xhide">
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10"
            transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true"
            :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Get in Touch</p>
                <p class="white--text headline">Need assistance? Help is just <br> a click away at Zebu.
                    <br>
                    Type your question for answers.
                </p>
                <div class="pos-abs prd-ipo-img text-right">
                    <img alt="main-bg.png" src="@/assets/contactus/contactheader.svg" width="100%" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 px-md-16 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 font-weight-bold mb-6">Get in Touch</p>
                <p class="white--text fs-18">Need assistance? Help is just a click away at Zebu. Simply type your query, and we’ll provide the answers you need.
                </p>
            </v-card>
        </div>
        <div class="py-md-16 mb-6">
            <v-row no-glutters class="px-md-16">
                <v-col cols="12" md="7" class="pr-md-8 pb-0">
                    <div class="px-6 px-sm-8 px-md-0">
                        <p class="secondary-infont mt-6 fs-32 d-none d-md-block">Customer services</p>
                        <p class="secondary-infont mt-6 fs-24 d-md-none text-center">Customer services</p>

                        <v-row>
                            <v-col cols="12" md="6" class="text-center text-md-left">
                                <v-card href="tel:+91 93 8010 8010" rel="noopener noreferrer" target="_blank"
                                    data-aos="flip-up" width="100%"
                                    class="box-s1 hcard px-2 pt-8 pb-6 sec-two-sty1 rounded-xl text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"
                                        fill="none">
                                        <path
                                            d="M24 0C12.42 0 3 9.42 3 21V34.5C3 37.809 5.691 40.5 9 40.5H13.5C14.328 40.5 15 39.828 15 39V24C15 23.172 14.328 22.5 13.5 22.5H6V21C6 11.0745 14.0745 3 24 3C33.9255 3 42 11.0745 42 21V22.5H34.5C33.672 22.5 33 23.172 33 24V39C33 39.828 33.672 40.5 34.5 40.5H39C40.098 40.5 41.1135 40.182 42 39.666V40.5C42 42.981 39.981 45 37.5 45H28.5C27.672 45 27 45.672 27 46.5C27 47.328 27.672 48 28.5 48H37.5C41.6355 48 45 44.6355 45 40.5V21C45 9.42 35.58 0 24 0Z"
                                            fill="#666666" />
                                    </svg>
                                    <p class="font-weight-bold title mb-0 mt-3">Sales & Support</p>
                                    <p class=" font-weight-light">Timing 09:00 AM To 11:00 PM</p>

                                    <p class="mb-0">
                                        <svg class="mt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_1116_12961)">
                                                <path
                                                    d="M13.1674 11.0211L10.4504 9.81407C10.1681 9.68782 9.85191 9.65859 9.55124 9.73096C9.25058 9.80332 8.98233 9.9732 8.78838 10.2141L8.00538 11.1931C6.72168 10.3693 5.62893 9.28067 4.80038 8.00007L5.77938 7.21707C6.02003 7.02318 6.18977 6.75511 6.26212 6.45466C6.33448 6.15421 6.30539 5.83826 6.17938 5.55607L4.97938 2.83207C4.84476 2.52875 4.60677 2.28311 4.30786 2.13897C4.00895 1.99482 3.66855 1.96155 3.34738 2.04507L1.04738 2.64507C0.714076 2.73182 0.424442 2.93838 0.233866 3.22526C0.0432902 3.51214 -0.034852 3.8592 0.0143827 4.20007C0.442982 7.17478 1.82344 9.93069 3.94914 12.0553C6.07484 14.1799 8.83145 15.559 11.8064 15.9861C12.1473 16.0343 12.4941 15.9553 12.7804 15.764C13.0667 15.5726 13.2725 15.2825 13.3584 14.9491L13.9584 12.6491C14.0418 12.328 14.008 11.9876 13.863 11.6892C13.718 11.3908 13.4714 11.1539 13.1674 11.0211Z"
                                                    fill="#0037B7" />
                                                <path
                                                    d="M10 8C10 8.26522 10.1054 8.51957 10.2929 8.70711C10.4804 8.89464 10.7348 9 11 9C11.2652 9 11.5196 8.89464 11.7071 8.70711C11.8946 8.51957 12 8.26522 12 8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5C7 5.26522 7.10536 5.51957 7.29289 5.70711C7.48043 5.89464 7.73478 6 8 6C8.53043 6 9.03914 6.21071 9.41421 6.58579C9.78929 6.96086 10 7.46957 10 8Z"
                                                    fill="#0037B7" />
                                                <path
                                                    d="M8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1C7 1.26522 7.10536 1.51957 7.29289 1.70711C7.48043 1.89464 7.73478 2 8 2C9.59081 2.00159 11.116 2.63424 12.2409 3.75911C13.3658 4.88399 13.9984 6.40919 14 8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C15.9976 5.879 15.154 3.84555 13.6542 2.34578C12.1544 0.846002 10.121 0.00238211 8 0Z"
                                                    fill="#0037B7" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1116_12961">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span class="font-weight-bold text-none txt-fobly ml-3">93 8010 8010</span>
                                    </p>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card href="tel:+91 93 8030 8030" rel="noopener noreferrer" target="_blank"
                                    data-aos="flip-up" width="100%"
                                    class="box-s1 px-2 hcard pt-8 pb-6 sec-two-sty1 rounded-xl text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"
                                        fill="none">
                                        <g clip-path="url(#clip0_1116_12986)">
                                            <path
                                                d="M10.5 33C13.3995 33 15.75 30.6495 15.75 27.75C15.75 24.8505 13.3995 22.5 10.5 22.5C7.60051 22.5 5.25 24.8505 5.25 27.75C5.25 30.6495 7.60051 33 10.5 33Z"
                                                fill="#666666" />
                                            <path
                                                d="M10.5 36C4.7115 36 0 40.71 0 46.5C0 47.328 0.6705 48 1.5 48H19.5C20.3295 48 21 47.328 21 46.5C21 40.71 16.2885 36 10.5 36Z"
                                                fill="#666666" />
                                            <path
                                                d="M37.5 33C40.3995 33 42.75 30.6495 42.75 27.75C42.75 24.8505 40.3995 22.5 37.5 22.5C34.6005 22.5 32.25 24.8505 32.25 27.75C32.25 30.6495 34.6005 33 37.5 33Z"
                                                fill="#666666" />
                                            <path
                                                d="M37.5 36C31.7115 36 27 40.71 27 46.5C27 47.328 27.6705 48 28.5 48H46.5C47.3295 48 48 47.328 48 46.5C48 40.71 43.2885 36 37.5 36Z"
                                                fill="#666666" />
                                            <path
                                                d="M44.25 0H23.25C21.1815 0 19.5 1.683 19.5 3.75V22.5C19.5 23.076 19.8315 23.6025 20.349 23.8515C20.5575 23.9505 20.778 24 21 24C21.3345 24 21.666 23.8875 21.9375 23.6715L29.0265 18H44.25C46.3185 18 48 16.317 48 14.25V3.75C48 1.683 46.3185 0 44.25 0Z"
                                                fill="#666666" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1116_12986">
                                                <rect width="48" height="48" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p class="font-weight-bold title mb-0 mt-3">Dealing Desk</p>
                                    <p class=" font-weight-light">Timing 9:00 AM To 11:55 PM</p>

                                    <p class="mb-0">
                                        <svg class="mt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_1116_12961)">
                                                <path
                                                    d="M13.1674 11.0211L10.4504 9.81407C10.1681 9.68782 9.85191 9.65859 9.55124 9.73096C9.25058 9.80332 8.98233 9.9732 8.78838 10.2141L8.00538 11.1931C6.72168 10.3693 5.62893 9.28067 4.80038 8.00007L5.77938 7.21707C6.02003 7.02318 6.18977 6.75511 6.26212 6.45466C6.33448 6.15421 6.30539 5.83826 6.17938 5.55607L4.97938 2.83207C4.84476 2.52875 4.60677 2.28311 4.30786 2.13897C4.00895 1.99482 3.66855 1.96155 3.34738 2.04507L1.04738 2.64507C0.714076 2.73182 0.424442 2.93838 0.233866 3.22526C0.0432902 3.51214 -0.034852 3.8592 0.0143827 4.20007C0.442982 7.17478 1.82344 9.93069 3.94914 12.0553C6.07484 14.1799 8.83145 15.559 11.8064 15.9861C12.1473 16.0343 12.4941 15.9553 12.7804 15.764C13.0667 15.5726 13.2725 15.2825 13.3584 14.9491L13.9584 12.6491C14.0418 12.328 14.008 11.9876 13.863 11.6892C13.718 11.3908 13.4714 11.1539 13.1674 11.0211Z"
                                                    fill="#0037B7" />
                                                <path
                                                    d="M10 8C10 8.26522 10.1054 8.51957 10.2929 8.70711C10.4804 8.89464 10.7348 9 11 9C11.2652 9 11.5196 8.89464 11.7071 8.70711C11.8946 8.51957 12 8.26522 12 8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5C7 5.26522 7.10536 5.51957 7.29289 5.70711C7.48043 5.89464 7.73478 6 8 6C8.53043 6 9.03914 6.21071 9.41421 6.58579C9.78929 6.96086 10 7.46957 10 8Z"
                                                    fill="#0037B7" />
                                                <path
                                                    d="M8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1C7 1.26522 7.10536 1.51957 7.29289 1.70711C7.48043 1.89464 7.73478 2 8 2C9.59081 2.00159 11.116 2.63424 12.2409 3.75911C13.3658 4.88399 13.9984 6.40919 14 8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C15.9976 5.879 15.154 3.84555 13.6542 2.34578C12.1544 0.846002 10.121 0.00238211 8 0Z"
                                                    fill="#0037B7" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1116_12961">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span class="font-weight-bold text-none txt-fobly ml-3">93 8030 8030</span>
                                    </p>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <v-card data-aos="flip-up" width="100%" rel="noopener noreferrer"
                                    href="https://maps.app.goo.gl/wt6pSw2VjR2iiAj48" target="_blank"
                                    class="box-s1 hcard px-2 pt-8 pb-6 sec-two-sty1 rounded-xl">
                                    <v-row>
                                        <v-col cols="12" md="6" class="text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48"
                                                viewBox="0 0 49 48" fill="none">
                                                <g clip-path="url(#clip0_1116_12970)">
                                                    <path
                                                        d="M45.1326 16.5488C44.5357 17.6598 43.8684 18.7315 43.1346 19.7573C43.914 22.2797 44.1709 24.9345 43.8895 27.5594C43.6082 30.1844 42.7944 32.7245 41.4981 35.0243L38.9151 31.7333C38.7573 31.5341 38.5506 31.3791 38.3151 31.2833L32.9691 29.0213C32.6986 28.9061 32.4 28.8738 32.1111 28.9283L25.7871 30.1043C25.5343 30.1512 25.2977 30.2624 25.1002 30.4271C24.9026 30.5918 24.7508 30.8045 24.6591 31.0448L22.4661 36.8108C22.3512 37.1138 22.3376 37.4459 22.4271 37.7573L23.8026 42.5573L22.7526 44.9168C19.0326 44.5808 15.489 43.177 12.5481 40.8743L13.7961 40.3283C14.063 40.2131 14.2904 40.0222 14.4501 39.7793L17.0121 35.8913C17.2202 35.5756 17.3013 35.193 17.2392 34.82C17.1772 34.447 16.9766 34.1112 16.6776 33.8798L13.0071 31.0388L11.5386 28.4138L10.2501 25.7243L12.9501 22.5203L17.6526 21.6503C17.9824 21.5892 18.2822 21.4193 18.504 21.1678C18.7259 20.9162 18.8571 20.5976 18.8766 20.2628L19.1391 15.7718C19.1609 15.4018 19.045 15.0369 18.8136 14.7473L16.1721 11.4383L14.1021 9.02331C16.0797 7.76471 18.2691 6.87531 20.5641 6.39831C20.9123 5.24187 21.4261 4.14195 22.0896 3.13281C17.37 3.63605 12.93 5.61746 9.40344 8.79418C5.87688 11.9709 3.44406 16.1805 2.45228 20.8221C1.46049 25.4638 1.96044 30.3 3.88075 34.6406C5.80106 38.9812 9.04357 42.6041 13.1454 44.9923C17.2472 47.3804 21.9987 48.4117 26.7214 47.9388C31.4442 47.4659 35.8969 45.5131 39.4438 42.3592C42.9907 39.2052 45.4505 35.0113 46.4722 30.3762C47.4938 25.7411 47.025 20.9016 45.1326 16.5488Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M33.5 0.00110267C32.1408 -0.0187389 30.791 0.229368 29.5276 0.731254C28.2643 1.23314 27.1122 1.97897 26.1372 2.92616C25.1622 3.87334 24.3832 5.00333 23.845 6.25157C23.3067 7.49982 23.0196 8.84188 23 10.2011C23 16.0511 31.55 24.1511 32.45 25.0511C32.5855 25.1932 32.7484 25.3064 32.9289 25.3837C33.1093 25.461 33.3037 25.5009 33.5 25.5009C33.6963 25.5009 33.8907 25.461 34.0711 25.3837C34.2516 25.3064 34.4145 25.1932 34.55 25.0511C35.45 24.1511 44 16.0511 44 10.2011C43.9804 8.84188 43.6933 7.49982 43.155 6.25157C42.6168 5.00333 41.8378 3.87334 40.8628 2.92616C39.8878 1.97897 38.7357 1.23314 37.4724 0.731254C36.209 0.229368 34.8592 -0.0187389 33.5 0.00110267ZM33.5 13.5011C32.9067 13.5011 32.3266 13.3252 31.8333 12.9955C31.3399 12.6659 30.9554 12.1973 30.7284 11.6492C30.5013 11.101 30.4419 10.4978 30.5576 9.91583C30.6734 9.33389 30.9591 8.79934 31.3787 8.37978C31.7982 7.96022 32.3328 7.6745 32.9147 7.55875C33.4967 7.44299 34.0999 7.5024 34.6481 7.72946C35.1962 7.95653 35.6648 8.34105 35.9944 8.83439C36.3241 9.32774 36.5 9.90776 36.5 10.5011C36.4976 11.296 36.1808 12.0577 35.6187 12.6198C35.0566 13.1819 34.2949 13.4987 33.5 13.5011Z"
                                                        fill="#666666" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1116_12970">
                                                        <rect width="48" height="48" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            <p class="font-weight-bold title mb-0 mt-3">HQ in Chennai</p>
                                            <p class="mb-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    viewBox="0 0 16 16" fill="none">
                                                    <path
                                                        d="M14.642 4.52061L1.14203 0.520608C0.96553 0.468608 0.77603 0.516608 0.64653 0.646608C0.51703 0.776108 0.46853 0.966608 0.52053 1.14211L4.52053 14.6421C4.58003 14.8416 4.75653 14.9836 4.96403 14.9986C4.97603 14.9996 4.98803 15.0001 5.00003 15.0001C5.19403 15.0001 5.37153 14.8876 5.45403 14.7096L8.37653 8.37661L14.7095 5.45411C14.8985 5.36661 15.0135 5.17161 14.999 4.96411C14.9835 4.75661 14.8415 4.58011 14.642 4.52061Z"
                                                        fill="#0037B7" />
                                                </svg>
                                                <span class="font-weight-bold text-none txt-fobly ml-2">சென்னை
                                                    தலைமையகம்</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="12" md="6" class="text-center">
                                            <p class="font-weight-bold title">Address</p>
                                            <p class="font-weight-light mx-4 mx-md-0 mb-0 mb-md-3">No: 301, 4th Main
                                                Road,
                                                <br class="d-none d-md-block"> Burma Colony,<br
                                                    class="d-none d-md-block"> Perungudi,<br class="d-none d-md-block">
                                                Chennai, Tamil Nadu 600096.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" md="5" class="pb-0">
                    <div class="px-6 px-sm-8 px-md-0">

                        <v-btn large block color="#2A2A2A" @click="excMat()"
                            class="elevation-0 rounded-pill text-none brd-2 d-md-none mt-6">
                            <span class="white--text">Checkout Escalation Matrix</span>
                        </v-btn>

                        <p class="secondary-infont mt-6 fs-32 d-none d-md-block">Enquire</p>
                        <p class="secondary-infont mt-6 fs-24 d-md-none">Enquire</p>
                        <v-card width="100%"
                            class="price-card-sty1 elevation-0 rounded-xl pa-6 sec-two-sty2 rounded-xl text-center">
                            <v-form ref="form" v-model="valid" lazy-validation
                                @submit.prevent="loader == false ? setEnquire() : ''">

                                <v-text-field v-model="name" :disabled="loader" :rules="nameRules" required block flat
                                    solo background-color="#ffffff" class="elevation-0 rounded-pill caption"
                                    label="Enter your name">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2"
                                            alt="user-icon">
                                    </template>
                                </v-text-field>
                                <v-text-field v-model="mobile" @keypress="NumberValid($event)" :maxlength="10"
                                    :disabled="loader" :rules="mobileRules" required block flat solo
                                    background-color="#ffffff" class="elevation-0 rounded-pill caption"
                                    label="Enter your mobile number">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2"
                                            alt="phone-icon">
                                    </template>
                                </v-text-field>
                                <v-text-field v-model="email" :disabled="loader" :rules="emailRules" required block flat
                                    solo background-color="#ffffff" class="elevation-0 rounded-pill caption"
                                    label="Enter your email address">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/send-message.svg" width="100%" class="mx-2"
                                            alt="send-msg-icon">
                                    </template>
                                </v-text-field>
                                <v-text-field v-model="cityf" :disabled="loader" :rules="cityRules" required block flat
                                    solo background-color="#ffffff" class="elevation-0 rounded-pill caption"
                                    label="Enter your current city">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/location.svg" width="100%" class="mx-2"
                                            alt="location-icon">
                                    </template>
                                </v-text-field>

                                <p class="font-weight-light">
                                    Our representatives will give you a call back <br> within the next 24 hours.
                                </p>

                                <v-btn large color="#2A2A2A" type="submit" :loading="loader" :disabled="loader"
                                    class="elevation-0 rounded-pill text-none brd-2 d-none d-md-inline-flex">
                                    <span class="white--text">Reach out to us</span>
                                </v-btn>
                                <v-btn large color="#2A2A2A" type="submit" :loading="loader" :disabled="loader"
                                    class="elevation-0 rounded-pill text-none brd-2 d-md-none" block>
                                    <span class="white--text">Reach out to us</span>
                                </v-btn>
                            </v-form>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" md="12" class="mb-6 pt-8 pt-md-16" id="loca">
                    <div class="px-6 px-sm-8 px-md-0 d-none d-md-block">
                        <v-card data-aos="zoom-in-right" color="#E5EBEC" class="elevation-0 brd-r-32 px-8 py-10 ">
                            <p class="secondary-font mb-0 fs-50">Our locations</p>
                            <p class="font-weight-bold fs-20 lh-28 mb-10">Find your nearest office from the list below
                            </p>

                            <v-row no-glutters>
                                <div v-for="(l, o) in locations" :key="o">
                                    <v-btn @click="changeLoaction(l)" color="#2A2A2A"
                                        class="elevation-0 rounded-pill mr-2 text-none brd-2 mb-2"
                                        :outlined="l.tit != activelocation.tit ? true : false">
                                        <span :class="l.tit == activelocation.tit ? 'white--text' : ''">{{ l.tit
                                            }}</span>
                                    </v-btn>
                                </div>
                            </v-row>

                            <v-card class="elevation-0 brd-r-32 pa-1 mt-10 pos-rlt">
                                <div class="mapframe overflow-hidden">
                                    <div class="mapis">
                                        <iframe class="brd-r-32" type="text/html" width="100%" height="548px"
                                            :src="activelocation.map" frameborder="0"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>

                                <v-card v-if="activelocation.tit != 'overview'" class="pos-abs map-body-crd elevation-0"
                                    width="88%">
                                    <div class="pos-rlt">
                                        <v-card class="map-details-card map-crd0 pa-6" width="100%">
                                            <p class="font-weight-bold text-none txt-fobly">{{ activelocation.tit }}</p>
                                            <p class="font-weight-bold mb-0 ">{{ activelocation.name }}</p>
                                            <p class="font-weight-light mb-0">{{ activelocation.phone }}</p>
                                            <p class="font-weight-light mb-3 ">{{ activelocation.email }}</p>

                                            <p class="font-weight-light mb-0" v-html="activelocation.adds"></p>
                                        </v-card>
                                        <v-card class="map-details-card map-crd1 pos-abs pa-6" height="60px"
                                            width="90%">
                                        </v-card>
                                        <v-card class="map-details-card map-crd2 pos-abs pa-6" height="60px"
                                            width="80%">
                                        </v-card>
                                        <v-card class="map-details-card map-crd3 pos-abs pa-6" height="60px"
                                            width="70%">
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-card>
                        </v-card>
                    </div>
                    <v-card data-aos="zoom-in-right" color="#E5EBEC"
                        class="elevation-0 rounded-0 px-6 px-sm-8 py-10 d-md-none">
                        <p class="secondary-font mb-0 fs-50">Our locations</p>
                        <p class="font-weight-bold fs-20 lh-28 mb-10">Find your nearest office from the list below</p>

                        <v-row no-glutters class="pl-4 pl-sm-6">
                            <div v-for="(l, o) in locations" :key="o">
                                <v-btn @click="changeLoaction(l)" color="#2A2A2A"
                                    class="elevation-0 rounded-pill mr-2 text-none brd-2 mb-2"
                                    :outlined="l.tit != activelocation.tit ? true : false">
                                    <span :class="l.tit == activelocation.tit ? 'white--text' : ''">{{ l.tit }}</span>
                                </v-btn>
                            </div>
                        </v-row>

                        <v-card class="elevation-0 brd-r-32 pa-1 mt-10 pos-rlt">
                            <div class="mapframe overflow-hidden">
                                <div class="mapis">
                                    <iframe class="brd-r-32" type="text/html" width="100%" height="548px"
                                        :src="activelocation.map" frameborder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </div>
                            </div>

                            <v-card v-if="activelocation.tit != 'overview'" class="pos-abs map-body-crd elevation-0"
                                width="88%">
                                <div class="pos-rlt">
                                    <v-card class="map-details-card map-crd0 pa-6" width="100%">
                                        <p class="font-weight-bold text-none txt-fobly">{{ activelocation.tit }}</p>
                                        <p class="font-weight-bold mb-0 mt-3">{{ activelocation.name }}</p>
                                        <p class="font-weight-light">{{ activelocation.phone }}</p>
                                        <p class="font-weight-light mb-0" v-html="activelocation.adds"></p>
                                    </v-card>
                                    <v-card class="map-details-card map-crd1 pos-abs pa-6" height="60px" width="90%">
                                    </v-card>
                                    <v-card class="map-details-card map-crd2 pos-abs pa-6" height="60px" width="80%">
                                    </v-card>
                                    <v-card class="map-details-card map-crd3 pos-abs pa-6" height="60px" width="70%">
                                    </v-card>
                                </div>
                            </v-card>
                        </v-card>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12" class="mt-md-6" id="autp">
                    <div class="px-6 px-sm-8 px-md-0">
                        <div class="d-md-inline-flex mb-4 d-none">
                            <p class="secondary-font mb-0 fs-32 mr-5">Authorized Person</p>
                            <v-autocomplete dense @change="findCity" :items="states" v-model="state" flat solo
                                background-color="#F1F3F8"
                                class="footer-select elevation-0 rounded-pill caption filed-width mr-2"
                                label="Select state" hide-details append-icon="mdi-chevron-down"></v-autocomplete>

                            <v-autocomplete dense @change="findval" :items="citys" v-model="city" flat solo
                                background-color="#F1F3F8"
                                class="footer-select elevation-0 rounded-pill caption filed-width mr-2"
                                label="Select City" hide-details append-icon="mdi-chevron-down"></v-autocomplete>

                            <v-select dense @change="findval" :items="['NSE', 'MCX','BSE']" v-model="exch" flat solo
                                background-color="#F1F3F8"
                                class="footer-select elevation-0 rounded-pill caption filed-width" label="Select City"
                                hide-details append-icon="mdi-chevron-down"></v-select>
                        </div>
                        <div class="d-md-none">
                            <p class="secondary-font mb-0 fs-28">Authorized Person</p>
                            <v-autocomplete @change="findCity" block :items="states" v-model="state" flat solo
                                background-color="#F1F3F8" class="footer-select elevation-0 rounded-pill caption"
                                label="Select state" append-icon="mdi-chevron-down"></v-autocomplete>

                            <v-autocomplete @change="findval" block :items="citys" v-model="city" flat solo
                                background-color="#F1F3F8" class="footer-select elevation-0 rounded-pill caption"
                                label="Select City" append-icon="mdi-chevron-down"></v-autocomplete>

                            <v-select @change="findval" block :items="['NSE', 'MCX','BSE']" v-model="exch" flat solo
                                background-color="#F1F3F8" class="footer-select elevation-0 rounded-pill caption"
                                label="Select City" append-icon="mdi-chevron-down"></v-select>
                        </div>
                        <v-card width="100%" class="price-card-sty elevation-0 rounded-xl d-none d-md-block">
                            <v-row no-glutters class="px-6 pt-6">
                                <v-col cols="6">
                                    <v-row no-glutters>
                                        <v-col cols="3"><span class="font-weight-bold fs-18">Authorised Person's
                                                Name</span></v-col>
                                        <v-col cols="3"><span class="font-weight-bold fs-18">Authoried Person Code
                                                (Exchange
                                                wise)</span></v-col>
                                        <v-col cols="3"><span class="font-weight-bold fs-18">Constitution</span></v-col>
                                        <v-col cols="3"><span class="font-weight-bold fs-18">Status</span></v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="6">
                                    <v-row no-glutters>
                                        <v-col cols="6"><span class="font-weight-bold fs-18">Registered
                                                Address</span></v-col>
                                        <v-col cols="6">
                                            <v-row no-glutters>
                                                <v-col cols="12"><span class="font-weight-bold fs-18">Terminal
                                                        Details</span></v-col>
                                                <v-col cols="6"><span class="font-weight-bold fs-18">Terminal Allotted
                                                        (Y/N)</span></v-col>
                                                <v-col cols="6"><span class="font-weight-bold fs-18">No. of
                                                        Terminals</span></v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-3"></v-divider>
                            <div v-if="AuthorizedPerson.length > 0">
                                <div v-for="(n, e) in AuthorizedPerson" :key="e">
                                    <v-row no-glutters class="px-6 py-3">
                                        <v-col cols="6">
                                            <v-row no-glutters>
                                                <v-col cols="3"><span class="font-weight-light txt-444">{{
                                                    n.AUTHORISED_PERSOIN_NAME }}</span></v-col>
                                                <v-col cols="3"><span class="font-weight-light txt-444">{{
                                                    n.AUTHORISED_PERSON_CODE }}</span></v-col>
                                                <v-col cols="3"><span class="font-weight-light txt-444">{{
                                                    n.CONSTITUTION
                                                }}</span></v-col>
                                                <v-col cols="3"><span class="font-weight-light txt-444">{{ n.STATUS
                                                        }}</span></v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-row no-glutters>

                                                <v-col cols="6"><span class="font-weight-light txt-444">{{ n.ADDRESS
                                                        }}</span></v-col>
                                                <v-col cols="6">
                                                    <v-row no-glutters>
                                                        <v-col cols="6" class="text-center"><span
                                                                class="font-weight-light txt-444">{{
                                                                    n.TERMINAL_ALLOTTED
                                                                }}</span></v-col>
                                                        <v-col cols="6" class="text-center"><span
                                                                class="font-weight-light txt-444">{{
                                                                    n.NO_OF_TERMINALS
                                                                }}</span></v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-divider v-if="AuthorizedPerson.length - 1 != e"></v-divider>
                                </div>
                            </div>
                            <div v-else class="pa-16 text-center">
                                <P v-if="aplistload" class="caption font-weight-light">Fetching authorized Person <br>
                                    from
                                    <b>{{ city }}...</b>
                                </P>

                                <P v-else class="caption font-weight-light">No authorized Person <br> in <b>{{ city
                                        }}</b>
                                </P>
                            </div>
                        </v-card>
                        <v-card width="100%" class="price-card-sty elevation-0 rounded-xl px-6 pt-6 d-md-none">
                            <div v-if="AuthorizedPerson.length > 0">
                                <div v-for="(n, e) in AuthorizedPerson" :key="e"
                                    :class="AuthorizedPerson.length - 1 != e ? 'pb-4' : ''">
                                    <p class="font-weight-bold fs-16 mb-0">{{ n.AUTHORISED_PERSOIN_NAME }} <span
                                            class="font-weight-light txt-444 fs-12">({{ n.STATUS }})</span> <span
                                            class="float-right fs-14">{{ n.NO_OF_TERMINALS }}</span></p>
                                    <p class="font-weight-light txt-444 fs-14">{{ n.AUTHORISED_PERSON_CODE }} <span
                                            class="fs-12">({{
                                                n.CONSTITUTION }})</span></p>
                                    <p class="font-weight-regular fs-16 lh-24">{{ n.ADDRESS }}</p>
                                    <v-divider v-if="AuthorizedPerson.length - 1 != e"></v-divider>
                                </div>
                            </div>
                            <div v-else class="px-16 text-center">
                                <P v-if="aplistload" class="caption font-weight-light">Fetching authorized Person <br>
                                    from
                                    <b>{{ city }}...</b>
                                </P>

                                <P v-else class="caption font-weight-light">No authorized Person <br> in <b>{{ city
                                        }}</b>
                                </P>
                            </div>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" id="exm">
                    <div class="px-6 px-sm-8 px-md-0">
                        <p class="secondary-infont mt-6 fs-32 d-none d-md-block">Escalation Matrix</p>
                        <p class="secondary-infont mt-6 fs-24 d-md-none">Escalation Matrix</p>

                        <v-card width="100%"
                            class="price-card-sty1 elevation-0 rounded-xl pb-3 sec-two-sty2 d-none d-md-block">
                            <v-row no-glutters class="pt-6 px-6">
                                <v-col cols="2"><span class="font-weight-bold fs-18">Escalation Level</span></v-col>
                                <v-col cols="2"><span class="font-weight-bold fs-18">Contact person</span></v-col>
                                <v-col cols="2"><span class="font-weight-bold fs-18">Phone</span></v-col>
                                <v-col cols="2"><span class="font-weight-bold fs-18">Email</span></v-col>
                                <v-col cols="4"><span class="font-weight-bold fs-18">Address</span></v-col>
                            </v-row>
                            <v-divider class="mt-3"></v-divider>
                            <div v-for="(e, k) in Escalation" :key="k">
                                <v-row no-glutters class="pt-6 pb-3 px-6">
                                    <v-col cols="2" class="c-pb-0"><v-alert border="top" width="60%" colored-border
                                            :color="e.color" elevation="0" rounded="lg"> <span
                                                class="text-uppercase fs-14 font-weight-bold txt-666666 mb-0"> Level {{
                                                    e.level }}</span>
                                        </v-alert></v-col>
                                    <v-col cols="2">
                                        <p class="mb-0"><b>{{ e.name }}</b> <br>
                                            <span class="font-weight-light txt-444">{{ e.role }}
                                            </span>
                                        </p>
                                    </v-col>
                                    <v-col cols="2">
                                        <p class="mb-0">
                                            <span class="font-weight-light txt-444">{{ e.phone }}
                                            </span>
                                        </p>
                                    </v-col>
                                    <v-col cols="2">
                                        <p class="mb-0">
                                            <span class="font-weight-light txt-444">{{ e.email }}
                                            </span>
                                        </p>
                                    </v-col>
                                    <v-col cols="4">
                                        <p class="mb-2">
                                            <span class="font-weight-light txt-444">{{ e.adds }}
                                            </span>
                                        </p>
                                        <p class="mb-0">
                                            <span class="font-weight-light txt-444 caption">{{ e.time }}
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                            </div>
                            <div class="px-6 pt-6">

                                <p class="font-weight-bold title"><span class="font-weight-bold fs-18">In absence of a
                                        response/complaint not addressed to your satisfaction, you may lodge a complaint
                                        with:</span></p>
                                <ul>
                                    <li><span class="font-weight-light txt-444">SEBI at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://scores.sebi.gov.in/">
                                                https://scores.sebi.gov.in/</a></span></li>
                                    <li><span class="font-weight-light txt-444">NSE at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://investorhelpline.nseindia.com/NICEPLUS/">

                                                https://investorhelpline.nseindia.com/NICEPLUS/</a></span></li>
                                    <li><span class="font-weight-light txt-444">MCX at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://www.mcxindia.com/Investor-Services">
                                                https://www.mcxindia.com/Investor-Services</a></span></li>
                                    <li><span class="font-weight-light txt-444">CDSL at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://www.cdslindia.com/Footer/grievances.aspx">https://www.cdslindia.com/Footer/grievances.aspx
                                            </a></span></li>

                                </ul>
                                <p><span class="font-weight-light txt-444">Please quote your Service Ticket/Complaint
                                        Ref
                                        No.
                                        while raising your complaint at SEBI SCORES/Exchange portal.</span></p>
                            </div>
                        </v-card>
                        <div class="d-md-none">
                            <v-card width="100%" v-for="(e, k) in Escalation" :key="k"
                                class="price-card-sty1 elevation-0 rounded-xl pb-3 sec-two-sty2 pa-6"
                                :class="k != Escalation.length - 1 ? 'mb-6' : ''">
                                <v-alert dense border="top" width="90px" colored-border :color="e.color" elevation="0"
                                    class="mb-6" rounded="lg"> <span
                                        class="text-uppercase fs-14 font-weight-bold txt-666666"> Level
                                        {{
                                            e.level }}</span>
                                </v-alert>
                                <v-list-item class="px-0">
                                    <v-list-item-avatar tile size="24" class="pos-rlt mt-n1">
                                        <img src="@/assets/contactus/user-c-frame.svg" alt="user-icon">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="fs-16"> <b>{{ e.name }}</b> <br>
                                            <span class="font-weight-light txt-444">{{ e.role }}
                                            </span></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-avatar tile size="20">
                                        <img src="@/assets/contactus/send-message.svg" alt="send-message-icon">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="fs-16">
                                            <span class="font-weight-light txt-444">{{ e.email }}
                                            </span></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-avatar tile size="18">
                                        <img src="@/assets/contactus/phone.svg" alt="phone-icon">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="fs-16">
                                            <span class="font-weight-light txt-444">{{ e.phone }}
                                            </span></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-avatar tile size="20" class="pos-rlt mt-n6">
                                        <img src="@/assets/contactus/location.svg" alt="location-icon">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <p class="fs-16 font-weight-light txt-444 mb-0">{{ e.adds }}
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                                <p class="fs-16 mb-2 ml-9">
                                    <span class="font-weight-light txt-444 caption">{{ e.time }}
                                    </span>
                                </p>
                            </v-card>
                            <div class="pt-6">
                                <p class="font-weight-bold js-18"><span class="font-weight-bold fs-16">In absence of a
                                        response/complaint not addressed to your satisfaction, you may lodge a complaint
                                        with:</span></p>
                                <ul class="mb-3">
                                    <li class="mb-2"><span class="font-weight-light txt-444 fs-16">SEBI at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://scores.sebi.gov.in/">
                                                https://scores.sebi.gov.in/</a></span></li>
                                    <li class="mb-2"><span class="font-weight-light txt-444 fs-16">NSE at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://investorhelpline.nseindia.com/NICEPLUS/">

                                                https://investorhelpline.nseindia.com/<br>NICEPLUS/</a></span></li>
                                    <li class="mb-2"><span class="font-weight-light txt-444 fs-16">MCX at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://www.mcxindia.com/Investor-Services">
                                                https://www.mcxindia.com/Investor-Services</a></span></li>
                                    <li><span class="font-weight-light txt-444 fs-16">CDSL at
                                            <a target="_blank" rel="noopener noreferrer" class='mailto-txt'
                                                href="https://www.cdslindia.com/Footer/grievances.aspx">https://www.cdslindia.com/Footer/<br>grievances.aspx
                                            </a></span></li>

                                </ul>
                                <p><span class="font-weight-light txt-444">Please quote your Service Ticket/Complaint
                                        Ref
                                        No. while raising your complaint at SEBI SCORES/Exchange portal.</span></p>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="mt-md-3">
                    <div class="px-6 px-sm-8 px-md-0">
                        <p class="secondary-infont mt-6 fs-32 d-none d-md-block">Names and contact details of all Key
                            Managerial Personnel
                            including Compliance Officer</p>
                        <p class="secondary-infont fs-24 d-md-none">Names and contact details of all Key Managerial
                            Personnel
                            including Compliance Officer</p>
                        <v-card width="100%"
                            class="price-card-sty1 elevation-0 rounded-xl pb-3 sec-two-sty2 rounded-xl">
                            <v-data-table disable-sort :headers="listheaders" :items="KeyManagerial" hide-default-footer
                                class="elevation-0 pledge-table "></v-data-table>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" class="my-md-6">
                    <div class="px-6 px-sm-8 px-md-0">
                        <p class="secondary-infont mt-6 fs-32 d-none d-md-block">List of Authorised Persons (AP)
                            Cancelled
                            by Members on
                            Account
                            of Disciplinary Reasons</p>
                        <p class="secondary-infont mt-6 fs-24 d-md-none">List of Authorised Persons (AP) Cancelled by
                            Members on
                            Account
                            of Disciplinary Reasons</p>
                        <v-card width="100%"
                            class="price-card-sty1 elevation-0 rounded-xl pb-3 sec-two-sty2 rounded-xl d-none d-md-block">
                            <v-row no-glutters class="py-3">
                                <v-col cols="7">
                                    <v-row no-glutters>
                                        <v-col cols="2">
                                            <span class="font-weight-bold title ml-4">Sr. No.</span>
                                        </v-col>
                                        <v-col cols="7">
                                            <span class="font-weight-bold fs-18">Authorised Person's Name</span>
                                        </v-col>
                                        <v-col cols="3">
                                            <span class="font-weight-bold fs-18">Status</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="5">
                                    <v-row no-glutters>
                                        <v-col cols="12">
                                            <span class="font-weight-bold fs-18">Authorised Person Cancellation
                                                Details</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="font-weight-bold fs-18">Date</span>
                                        </v-col>
                                        <v-col ols="6">
                                            <span class="font-weight-bold fs-18">Reason</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <div v-for="(n, e) in Apcancelled" :key="e">
                                <v-row no-glutters class="pa-4">
                                    <v-col cols="7">
                                        <v-row no-glutters>
                                            <v-col cols="2"><span class="font-weight-light txt-444">{{ n.sn
                                                    }}</span></v-col>
                                            <v-col cols="7"><span class="font-weight-light txt-444">{{ n.APNAME
                                                    }}</span></v-col>
                                            <v-col cols="3"><span class="font-weight-light txt-444">{{ n.Status
                                                    }}</span></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-row no-glutters>
                                            <v-col cols="6">
                                                <span class="font-weight-light txt-444">{{ n.Date }}</span>
                                            </v-col>
                                            <v-col ols="6">
                                                <span class="font-weight-light txt-444">{{ n.Reason }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider v-if="Apcancelled.length - 1 != e"></v-divider>
                            </div>
                        </v-card>
                        <v-card width="100%"
                            class="price-card-sty1 elevation-0 rounded-xl pb-0 sec-two-sty2 mb-4 rounded-xl pa-6 d-md-none">
                            <div v-for="(n, e) in Apcancelled" :key="e"
                                :class="AuthorizedPerson.length - 1 != e ? 'pb-4' : ''">
                                <p class="font-weight-bold fs-16 mb-0">{{ n.APNAME }}</p>
                                <p class="font-weight-light txt-444 fs-16 mb-0">{{ n.Reason }} </p>
                                <p class="font-weight-regular fs-14">{{ n.Status }} <span
                                        class="font-weight-light txt-444 fs-12">({{
                                            n.Date }})</span></p>
                                <v-divider v-if="Apcancelled.length - 1 != e"></v-divider>
                            </div>
                        </v-card>
                    </div>
                </v-col>
            </v-row>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { zebumyntapi } from '../apiurl'

export default {

    data: () => ({
        loader: false,
        snackbar: false,
        snackbarclr: "default",
        snackmsgbar: "",

        valid: true,
        name: "",
        mobile: "",
        email: "",
        cityf: "",
        nameRules: [
            (v) => !!v || "Your Name is required",
            (v) => (v && v.length >= 3) || "Name must be more than 3 characters",
        ],
        emailRules: [
            (v) => !!v || "Your E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        mobileRules: [
            (v) => !!v || "Your mobile number is required",
            (v) =>
                /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
        ],
        cityRules: [
            (v) => !!v || "Your city is required",
        ],
        locations: [],

        listheaders: [
            { text: 'Sr.no', value: 'sn' },
            { text: 'Name of the Individual', value: 'Name' },
            { text: 'Designation', value: 'Designation' },
            { text: 'Mobile Number', value: 'MobileNumber' },
            { text: 'Email Id', value: 'EmailId' },
        ],
        Apcancelled: [],
        KeyManagerial: [],
        activelocation: {
            tit: '',
            map: '',
            name: '',
            email: '',
            phone: '',
            adds: ''
        },
        AuthorizedPerson: [],
        aplistload: true,
        defaultmap: {
            tit: 'overview', map:
                "https://www.google.com/maps/d/embed?mid=1WFBi1H6pa8j_aTi6VerIkKUvW6jwxiCf&z=5",
        },
        Escalation: [],
        locationdata: [],
        states: [],
        citys: [],
        state: "TAMIL NADU",
        city: "CHENNAI",
        exch: "NSE",
    }),
    created() {
        this.getLocation();
        this.contactpagejshondat()
    },

    methods: {
        NumberValid($event) {
            if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
                // 0-9 only
            } else {
                $event.preventDefault();
            }
        },
        changeLoaction(l) {
            this.activelocation = l;
        },
        findCity() {
            let city = this.locationdata.find(o => o.state === this.state);
            this.citys = city.city;
            this.city = this.citys[0];
            // console.log("citys", this.citys);
            this.findval();
        },
        findval() {
            this.aplistload = true;
            this.AuthorizedPerson = [];
            let data = JSON.stringify({
                "state": this.state,
                "city": this.city,
                "exch": this.exch
            });
            let config = {
                method: 'post',
                url: `${zebumyntapi}/user_data`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data) {
                        axiosThis.AuthorizedPerson = response.data
                        // for (let c = 0; c < value.length; c++) {
                        //     axiosThis.AuthorizedPerson.push({ name: value[c].AUTHORISED_PERSOIN_NAME, adds: value[c].ADDRESS, })
                        // }
                    }
                    axiosThis.aplistload = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getLocation() {
            this.citys = [];
            this.states = [];
            this.locationdata = [];
            let config = {
                method: 'post',
                url: 'https://dwld.zebuetrade.com/states_cities',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    // for (let s = 0; s < response.data.length; s++) {
                    for (const [key, value] of Object.entries(response.data)) {
                        axiosThis.states.push(key)
                        axiosThis.citys.push(value)
                        axiosThis.locationdata.push({ "state": key, "city": value, })
                    }
                    // }
                    axiosThis.citys = [];
                    let city = axiosThis.locationdata.find(o => o.state === axiosThis.state);
                    axiosThis.citys = city.city;
                    axiosThis.findval();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        excMat() {
            const element = document.getElementById("exm");
            // console.log("element", element);
            element.scrollIntoView();
        },
        setEnquire() {
            if (this.$refs.form.validate() == true) {
                this.loader = true;
                let config = {
                    method: 'post',
                    url: `${zebumyntapi}openaccInit?name=${this.name}&phoneno=${this.mobile}&email=${this.email}&location=${this.cityf}&ref=/contact`,
                    headers: {}
                };

                let axiosThis = this;
                axios.request(config)
                    .then((response) => {
                        if (response.data == 'record has been captured') {
                            axiosThis.snackmsgbar = 'Thank you for your response, One of our team representative will reach you shortly.';
                            axiosThis.$refs.form.reset();
                        } else {
                            axiosThis.snackmsgbar = response.data;
                        }
                        axiosThis.snackbar = true;
                        axiosThis.snackbarclr = "#2A2A2A";
                        axiosThis.loader = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        contactpagejshondat() {
            this.Escalation = []
            this.locations = []
            this.Apcancelled = []
            this.KeyManagerial = []
            let config = {
                method: 'get',
                url: `${zebumyntapi}/static/Downloads/download_docs/wsjsons/contact.json`,
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.Escalation = response.data.Escalation
                    axiosThis.Apcancelled = response.data.Apcancelled
                    axiosThis.locations = response.data.locations
                    axiosThis.KeyManagerial = response.data.KeyManagerial
                    axiosThis.activelocation = { ...axiosThis.locations[0] };
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

}
</script>